import React from 'react'
import TextColumn from './textColumn'
import PictureColumn from './pictureColumn'
import SwingALittle from '../utility/swingALittleAnimation'

const Grid = props => (
  <SwingALittle>
    <div
      className="section is-dark"
      style={{ padding: '0.7rem 0rem 0.7rem 0rem' }}
    >
      <div className="container">
        <div className="columns is-multiline">
          <TextColumn
            background={props.background}
            title={props.title1}
            text={props.text1}
          />
          <PictureColumn fluid={props.fluid1} alt={props.alt1} />
          <TextColumn
            background={props.background}
            title={props.title2}
            text={props.text2}
          />
          <PictureColumn fluid={props.fluid2} alt={props.alt2} />
          <PictureColumn fluid={props.fluid3} alt={props.alt3} />
          <TextColumn
            background={props.background}
            title={props.title3}
            text={props.text3}
          />
          <PictureColumn fluid={props.fluid4} alt={props.alt4} />
          <TextColumn
            background={props.background}
            title={props.title4}
            text={props.text4}
          />
        </div>
      </div>
    </div>
  </SwingALittle>
)

export default Grid
