import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const SwingALittle = ({ children }) => {
  const [ref, inView] = useInView({ threshold: 0 })

  return (
    <motion.div
      animate={
        inView
          ? {
              scaleX: [0, 1.2, 1],
              rotate: [0, 5, 0, -5, 0],
            }
          : {
              scaleX: 0,
              rotate: 0,
            }
      }
      transition={{ duration: 1 }}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

SwingALittle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SwingALittle
