import React from 'react'
import { motion } from 'framer-motion'

function SvgSoftwareDevelopmentForElectronics(props) {
  return (
    <svg
      viewBox="0 0 2000 1500"
      height="100vh"
      width="100vw"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <defs>
        <linearGradient
          y1={190.427}
          id="SoftwareDevelopmentForElectronics_svg__a"
          x1={292.571}
          y2={190.427}
          x2={1024.67}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-393.741 51.371) scale(1.49114)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={534.095}
          id="SoftwareDevelopmentForElectronics_svg__b"
          x1={387.268}
          y2={534.095}
          x2={1120.59}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1.517 -.00356 .00356 1.517 -389.087 21.756)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={399.557}
          id="SoftwareDevelopmentForElectronics_svg__c"
          x1={330.695}
          y2={399.557}
          x2={585.507}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={278.54}
          id="SoftwareDevelopmentForElectronics_svg__d"
          x1={392.954}
          y2={278.54}
          x2={513.998}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={306.474}
          id="SoftwareDevelopmentForElectronics_svg__e"
          x1={392.954}
          y2={306.474}
          x2={513.998}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={334.407}
          id="SoftwareDevelopmentForElectronics_svg__f"
          x1={392.954}
          y2={334.407}
          x2={513.998}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={197.942}
          id="SoftwareDevelopmentForElectronics_svg__g"
          x1={392.711}
          y2={197.942}
          x2={513.755}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={138.334}
          id="SoftwareDevelopmentForElectronics_svg__h"
          x1={109.077}
          y2={138.334}
          x2={227.134}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#4a45f5" offset={0.003} />
          <stop stopColor="#35b775" offset={0.956} />
        </linearGradient>
        <linearGradient
          y1={138.334}
          id="SoftwareDevelopmentForElectronics_svg__i"
          x1={654.366}
          y2={138.334}
          x2={772.423}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.55084 0 0 .55084 781.811 621.353)"
        >
          <stop stopColor="#4a45f5" offset={0.003} />
          <stop stopColor="#35b775" offset={0.981} />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M1411.14 1367.79c0-19.48 74.44-35.27 166.26-35.27s166.25 15.79 166.25 35.27c0 19.48-74.43 35.27-166.25 35.27s-166.26-15.79-166.26-35.27z"
          fill="#e6e6e6"
        />
        <motion.g
          style={{ originX: '1381px', originY: '504px' }}
          animate={{ rotate: [0, 5, 0] }}
          transition={{ duration: 1, loop: Infinity }}
        >
          <path
            d="M1344.63 549.664l-11.91 3.935-1.66 10.304a3.453 3.453 0 001.66 3.512c1.23.715 2.77.605 3.88-.278l7.61-6.055.42-11.418z"
            fill="#2f2e41"
          />
          <path
            d="M1381.09 504.427l-36.88 56.655-11.49-7.483 36.88-56.655a6.866 6.866 0 019.49-2.006 6.886 6.886 0 012 9.489z"
            fill="#35b775"
          />
          <path
            d="M1381.09 504.427l-3.6 5.535a11.724 11.724 0 01-11.49-7.484l3.6-5.534a6.866 6.866 0 019.49-2.006 6.886 6.886 0 012 9.489z"
            fill="#2f2e41"
          />
        </motion.g>
        <path
          d="M1541.63 610.531l-61.8 25.057-95.72-68.377s-24.42-51.275-53.72-26.858c-10.03 8.362 30.37 40.906 34.19 43.95 9.54 7.608 96.88 94.715 109.23 90.192 12.34-4.524 66.32-12.712 66.32-12.712l1.5-51.252z"
          fill="#fbc5c5"
        />

        <path
          d="M1697.86 544.015s-29.89-29.3-56.45-21.975c-26.56 7.325-20.46 20.755-54.64 28.079l-3.67 15.871s-19.36 7.924-26.85 14.65c-8.37 7.51-14.18 65.074-3.67 113.537 6.91 31.855-8.8 63.637-1.22 76.912l136.74-17.091s12.2-12.209 4.88-19.534c-7.33-7.325 4.88-61.041 4.88-61.041V544.015z"
          fill="#cfcce0"
        />
        <path
          d="M1617.29 1239.89l-2.44 58.6 21.97 29.3 26.86-24.42-2.44-65.93-43.95 2.45zM1854.13 1191.05s17.09 34.19 19.53 41.51c2.44 7.33 36.63 4.89 36.63 4.89l12.2-31.75-41.5-39.06-26.86 24.41z"
          fill="#fbc5c5"
        />
        <path
          d="M1550.14 760.102l6.11 120.862s19.53 173.356 26.85 202.656c7.33 29.3 34.19 170.92 34.19 170.92l65.92-9.77-19.53-197.77 2.44-39.07 36.63 56.16 139.17 146.5 53.72-43.95-139.18-163.59-36.62-146.503s4.88-75.691-36.63-104.991l-133.07 8.546zM1631.94 1308.25s-4.89-21.72-17.09-14.52c-12.21 7.2-41.51 43.82-41.51 43.82s-75.69 51.28-41.51 56.16c34.18 4.88 68.37-14.65 68.37-14.65s26.85-14.65 31.74-14.65c4.88 0 46.39-12.21 46.39-21.97 0-9.77-14.86-44.58-14.86-44.58s-16.88 15.28-31.53 10.39zM1900.97 1225.43s-13.15-17.97-21.47-6.49c-8.31 11.48-20.54 56.76-20.54 56.76s-48.91 77.24-15.62 68.06c33.29-9.18 56.82-40.74 56.82-40.74s18.77-24.16 23.25-26.11c4.47-1.95 37.65-29.72 33.75-38.68-3.9-8.95-31.42-34.92-31.42-34.92s-9.38 20.74-24.77 22.12z"
          fill="#2f2e41"
        />
        <path
          d="M1584.33 564.769s-28.08 8.546-35.41 20.754c-7.32 12.209-29.3 26.859-29.3 26.859l17.09 68.366 26.86-31.741 20.76-84.238z"
          fill="#cfcce0"
        />
        <path
          d="M1671.95 622.227l25.06 61.802-43.9 113.517s-75.75 6.621-51.33 35.921c8.36 10.035 73.51-22.948 76.55-26.767 7.61-9.548 62.11-104.299 57.59-116.645-4.52-12.346-12.71-66.326-12.71-66.326l-51.26-1.502z"
          fill="#fbc5c5"
        />
        <path
          d="M1646.59 551.34l19.53 95.225s39.07-26.858 68.37 2.441l-36.63-104.991-51.27 7.325z"
          fill="#cfcce0"
        />
        <path
          d="M1627.6 783.441l57.94 25.955-19.97 44.565-81.24-18.603 5.09-16.657 38.18-35.26z"
          fill="#2f2e41"
        />
        <path
          d="M1613.77 870.798l4.6-1.643 48.83 136.735-4.6 1.64-48.83-136.732z"
          opacity={0.2}
        />
      </g>
      <g>
        <motion.g
          animate={{
            rotate: [10, 0, 10],
          }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <path
            d="M42.89 368.159C50.81 224.555 166.187 107.47 309.675 97.656c136.365-9.582 260.621 78.179 297.185 209.9 3.483-.125 6.962-.265 10.475-.265 127.55.023 239.838 84.079 275.798 206.456 84.633-37.186 183.696-12.544 241.046 59.959H237.543c-110.158 0-199.35-91.241-194.857-201.307.058-1.411.125-2.824.203-4.24z"
            fill="url(#SoftwareDevelopmentForElectronics_svg__a)"
          />
        </motion.g>
        <motion.g
          animate={{
            rotate: [10, 0, 10],
          }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <path
            d="M1312.41 792.218c-6.3 146.182-122.24 266.702-268.09 278.442-138.6 11.41-266.077-76.344-304.883-209.893-3.541.17-7.079.355-10.653.398-129.754 1.536-245.01-82.598-283.088-206.649-85.64 38.864-186.72 4.025-245.946-69.029l912.13.015c112.06-1.348 203.91 90.379 200.68 202.401-.04 1.436-.09 2.874-.15 4.315z"
            fill="url(#SoftwareDevelopmentForElectronics_svg__b)"
          />
        </motion.g>
        <path
          d="M1250.76 548.477a137.93 137.93 0 0179.68 25.229h-159.26a137.912 137.912 0 0179.58-25.229z"
          fill="#35b775"
        />
      </g>
      <g>
        <path
          d="M1142.54 811.401V640.154a9.561 9.561 0 007.16-9.249c0-5.275-4.28-9.551-9.55-9.551H910.918c-5.275 0-9.552 4.276-9.552 9.551s4.277 9.551 9.552 9.551h.002l-5.307 171.096-42.608 54.532s-.358 318.256 0 0c318.255 0 318.025.552 279.535-54.683z"
          fill="#e6e6e6"
        />
        <path
          d="M963.972 841.447c0-2.982 31.421-5.399 70.178-5.399 38.76 0 70.18 2.417 70.18 5.399 0 2.981-31.42 5.398-70.18 5.398-38.757 0-70.178-2.417-70.178-5.398z"
          opacity={0.2}
          fill="url(#SoftwareDevelopmentForElectronics_svg__c)"
        />
        <path
          d="M910.917 640.456l231.623 6.746.1-7.161-231.723.415z"
          opacity={0.2}
        />
        <path
          d="M1078.27 700.513v21.054c0 .405-.33.733-.73.734h-.53V831.98c0 6.32-5.12 11.444-11.44 11.444h-68.533c-6.32-.001-11.442-5.124-11.441-11.444V726.148h-.378v-12.499h.378v-4.513h-.382v-12.884h.382v-6.703h-.388v-6.93h.388v-13.544c0-6.318 5.122-11.44 11.441-11.44h68.533c6.32 0 11.44 5.121 11.44 11.44v30.703h.53c.4.002.73.33.73.735z"
          fill="#3f3d56"
        />
        <path
          d="M1023.47 663.503h11.13c.45 0 .82.365.82.815v.962a.82.82 0 01-.82.815h-11.13a.82.82 0 01-.82-.815v-.962c0-.45.37-.815.82-.815zM1037.35 664.799a1.471 1.471 0 012.94 0 1.471 1.471 0 01-2.94 0z"
          fill="#e6e8ec"
        />
        <path
          d="M1071.53 671.338v161.591a7.258 7.258 0 01-7.26 7.257h-65.6a7.248 7.248 0 01-7.261-7.257V671.338a7.26 7.26 0 017.261-7.26h9.82v1.26a5.981 5.981 0 005.98 5.978h33.36c3.3 0 5.98-2.677 5.98-5.978v-1.26h10.46a7.259 7.259 0 017.26 7.26z"
          fill="#fff"
        />
        <path
          d="M998.267 770.458h66.673v8.655h-66.673v-8.655z"
          fill="url(#SoftwareDevelopmentForElectronics_svg__d)"
        />
        <path
          d="M998.267 785.845h66.673v8.655h-66.673v-8.655z"
          fill="url(#SoftwareDevelopmentForElectronics_svg__e)"
        />
        <path
          d="M998.267 801.232h66.673v8.655h-66.673v-8.655z"
          fill="url(#SoftwareDevelopmentForElectronics_svg__f)"
        />
        <path
          d="M998.133 708.27h66.677v44.237h-66.677V708.27z"
          fill="url(#SoftwareDevelopmentForElectronics_svg__g)"
        />
        <path
          d="M906.926 646.795h-34.193s-56.28 30.158-16.778 92.173c0 0 16.141-3.823 33.981 9.344 0 0 10.406-99.181 16.99-101.517z"
          fill="url(#SoftwareDevelopmentForElectronics_svg__h)"
        />
        <path
          d="M896.755 647.106c-6.126 25.588-13.051 91.586-13.051 91.586-15.939-11.764-30.511-9.967-33.438-9.45a113.367 113.367 0 005.689 9.973s16.141-3.823 33.981 9.345c0 0 10.406-99.181 16.99-101.518l-10.171.064z"
          opacity={0.2}
        />
        <path
          d="M855.743 678.864l-2.974 14.442-37.366-6.792-.012-.004-.85-16.99h7.221l33.981 9.344z"
          fill="#3f3d56"
        />
        <path
          d="M821.159 669.52l-5.756 16.994-.012-.004-.85-16.99h6.618z"
          opacity={0.2}
        />
        <path
          d="M850.221 685.236c0-5.396 1.902-9.77 4.247-9.77 2.346 0 4.248 4.374 4.248 9.77 0 5.395-1.902 9.769-4.248 9.769-2.345 0-4.247-4.374-4.247-9.769zM832.381 891.692a1.298 1.298 0 01-1.298-1.298V685.236a1.298 1.298 0 112.596 0v205.158c0 .717-.581 1.298-1.298 1.298z"
          fill="#3f3d56"
        />
        <path
          d="M850.219 927.797a1.299 1.299 0 01-1.272-1.05l-17.84-91.456a1.298 1.298 0 012.36-.96l47.148 71.916a1.3 1.3 0 01-2.172 1.424l-43.521-66.384 16.573 84.963a1.298 1.298 0 01-1.276 1.547z"
          fill="#3f3d56"
        />
        <path
          d="M783.108 911.655a1.297 1.297 0 01-1.085-2.009l49.272-75.182a1.298 1.298 0 012.172 1.423l-49.272 75.182c-.24.366-.649.587-1.087.586z"
          fill="#3f3d56"
        />
        <path
          d="M1142.26 646.795h34.2s56.28 30.158 16.77 92.173c0 0-16.14-3.823-33.98 9.344 0 0-10.4-99.181-16.99-101.517z"
          fill="url(#SoftwareDevelopmentForElectronics_svg__i)"
        />
        <path
          d="M1152.44 647.106c6.12 25.588 13.05 91.586 13.05 91.586 15.93-11.764 30.51-9.967 33.43-9.45a110.173 110.173 0 01-5.69 9.973s-16.14-3.823-33.98 9.345c0 0-10.4-99.181-16.99-101.518l10.18.064z"
          opacity={0.2}
        />
        <path
          d="M1193.45 678.864l2.97 14.442 37.37-6.792.01-.004.85-16.99h-7.22l-33.98 9.344z"
          fill="#3f3d56"
        />
        <path
          d="M1228.03 669.52l5.76 16.994.01-.004.85-16.99h-6.62z"
          opacity={0.2}
        />
        <path
          d="M1190.47 685.236c0-5.396 1.91-9.77 4.25-9.77 2.35 0 4.25 4.374 4.25 9.77 0 5.395-1.9 9.769-4.25 9.769-2.34 0-4.25-4.374-4.25-9.769zM1216.81 891.692c-.72 0-1.3-.581-1.3-1.298V685.236c0-.717.58-1.298 1.3-1.298.72 0 1.3.581 1.3 1.298v205.158c0 .717-.58 1.298-1.3 1.298z"
          fill="#3f3d56"
        />
        <path
          d="M1198.97 927.797a1.31 1.31 0 01-1.28-1.547l16.58-84.964-43.52 66.385c-.4.6-1.2.768-1.8.374a1.291 1.291 0 01-.37-1.798l47.14-71.916a1.297 1.297 0 012.36.96l-17.84 91.456a1.297 1.297 0 01-1.27 1.05z"
          fill="#3f3d56"
        />
        <path
          d="M1266.08 911.655a1.3 1.3 0 01-1.09-.586l-49.27-75.182c-.39-.6-.22-1.403.38-1.795a1.29 1.29 0 011.79.372l49.28 75.182c.26.398.28.908.05 1.328-.22.419-.66.681-1.14.681z"
          fill="#3f3d56"
        />
        <path
          d="M995.002 801.638l9.298-2.59s3.65-.727 1.42-7.843c-2.22-7.116-6.64-5.125-6.64-5.125l-8.252 2.398-3.258 1.006 4.584 6.431h4.286l-1.438 5.723z"
          fill="#2e2d3d"
        />
        <path
          d="M977.027 782.836l-2.918 11.658 14.954 14.493 1.285.322 3.43-.963 2.919-11.658-2.956-3.109 3.021-.791-6.448-6.261-3.365.706-2.7-2.679 1.822-.729.172-.686-2.207-1.736-7.009 1.433z"
          fill="#3f3d56"
        />
        <path
          d="M991.038 799.279l-1.932 7.715-8.912-8.426 2.232-7.822 8.612 8.533z"
          fill="#35b775"
        />
        <path
          stroke="#3f3d56"
          d="M977.027 782.836l-.409 1.635 14.87 14.466 1.286.321 3.322-.899.183-.004.418-1.667-2.956-3.109 3.021-.791-6.448-6.261-3.365.706-2.7-2.679 1.822-.729.172-.686-2.207-1.736-7.009 1.433z"
          fill="#2e2d3d"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path
          d="M983.693 783.503c-.663-.166-1.109-.665-.997-1.115.113-.45.742-.68 1.404-.514.663.166 1.109.665.997 1.115-.113.45-.742.68-1.404.514zM993.139 795.979c-.662-.166-1.108-.665-.996-1.114.113-.45.741-.68 1.404-.514.663.166 1.109.665.996 1.115-.112.449-.741.679-1.404.513z"
          fill="#35b775"
        />
        <path
          d="M1001.18 816.16l-2.026-6.897s5.276-12.577-.811-13.794c-6.086-1.217-6.492 11.766-6.492 11.766l2.434 7.708 6.895 1.217zM947.403 786.633c0-2.091.398-4.09 1.123-5.924 2.361-5.969 8.184-10.192 14.993-10.192 8.901 0 16.116 7.216 16.116 16.116 0 8.901-7.215 16.117-16.116 16.117s-16.116-7.216-16.116-16.117z"
          fill="#ffb6b6"
        />
        <path
          d="M968.733 798.01s.948 11.85 3.318 12.798c2.37.948-25.596 7.584-25.596 7.584l7.11-8.058s1.422-12.324-1.896-15.168l17.064 2.844z"
          fill="#ffb6b6"
        />
        <path
          d="M991.445 812.509l6.492-.811s7.713 4.057 7.713 9.331c0 5.274 4.46 13.795 4.46 13.795s3.24 11.36-4.06 12.171c-7.302.812-13.793-12.171-13.793-12.171l-.812-22.315z"
          fill="#3f3d56"
        />
        <path
          d="M995.097 877.018s14.203-13.388 18.253-12.577c4.06.811 12.18 2.029 12.18 8.114 0 6.086-13.39 20.286-13.39 20.286s-10.15 22.315-19.883 23.126a168.225 168.225 0 00-19.069 2.84s4.463 16.229 2.029 20.692c-2.435 4.463-11.766 9.737-12.578 9.332-.811-.406-12.171-10.955-17.04-25.966-4.869-15.012-8.114-26.372-3.651-27.184 4.462-.811 53.149-18.663 53.149-18.663z"
          fill="#2f2e41"
        />
        <path
          d="M1024.31 871.338s2.84-.405 2.43 6.492c-.4 6.897-1.62 31.646-3.65 36.514-2.03 4.869-2.43 10.549-1.62 12.578.81 2.028 2.43 5.274-.41 5.68-2.84.406-14.6-1.217-15.82-1.623-1.22-.406-2.43-7.303-1.62-7.303s2.84-.406 2.03-3.246c-.82-2.84-1.22-2.84 0-3.651 1.21-.812 4.46-2.029 3.65-2.84-.81-.812-1.63-19.881-1.63-19.881l16.64-22.72z"
          fill="#2f2e41"
        />
        <path
          d="M1020.25 931.385s6.9-2.029 7.71 2.434c0 0 1.22 6.897-4.06 9.332a64.96 64.96 0 00-9.73 5.68s-8.52 3.245-8.93-.812c-.41-4.057 1.62-18.663 1.62-18.663l13.39 2.029z"
          fill="#3f3d56"
        />
        <path
          d="M974.405 936.253l-12.983-10.548s2.434 12.983-.811 15.417l-1.483 4.334 4.152 3.397s12.748-2.051 11.125-12.6z"
          fill="#2f2e41"
        />
        <path
          d="M956.148 918.402s8.52 2.434 7.303 10.954c-1.217 8.52-2.778 17.777-2.778 17.777s-.468 22.795-6.554 24.418c-6.086 1.623-7.709-1.623-7.709-1.623s-6.917-17.813-4.868-23.126c2.48-6.431 6.055-13.652 4.868-15.823-1.167-2.136.812-13.795 9.738-12.577zM952.366 811.698s2.159 3.245.13 3.651c-2.028.406-3.245 11.766-2.434 0 .811-11.766 47.875-3.651 47.875-3.651l-3.652 27.994s4.463 32.458 3.652 38.138c-.812 5.68 9.733 4.868 3.653 11.766-6.088 6.897-16.636 12.983-38.139 10.954-21.503-2.029-24.343-1.623-24.343-4.869 0-3.245 4.868-30.834 3.245-33.269-1.623-2.434-4.868-5.274-3.245-8.114 1.622-2.84 3.245-4.463.811-6.897s-3.651-4.869-3.651-6.086c0-1.217-7.507-13.693-5.681-21.097 1.827-7.405 10.957-8.52 12.986-8.52h8.793z"
          fill="#3f3d56"
        />
        <path
          d="M947.403 802.75s-2.342-23.237 2.093-27.961c4.435-4.723 7.893-5.427 14.245-5.43 6.285-.002 11.157 4.873 11.157 4.873l3.087 4.202-6.248 3.199s2.223 10.898.457 12.098c-1.767 1.199-5.299-7.098-8.222-2.985-3.548 4.993-1.663 8.394-4.648 8.124-2.984-.27-5.326.131-6.958.388"
          fill="#2f2e41"
        />
      </g>
      <g>
        <path
          d="M409.24 569.02c0-2.256 10.047-4.085 22.441-4.085 12.393 0 22.44 1.829 22.44 4.085 0 2.256-10.047 4.085-22.44 4.085-12.394 0-22.441-1.829-22.441-4.085z"
          opacity={0.1}
          fill="#35b775"
        />
        <path
          d="M418.435 564.686l.32-.244a1.683 1.683 0 01-.32.244z"
          fill="#a1616a"
        />
        <path
          stroke="#535461"
          strokeWidth={2}
          d="M195.033 422.043s-25.297-36.5-21-70.948a67.293 67.293 0 00-8.1-41.781 113.198 113.198 0 00-11.899-16.934"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path
          d="M156.612 270.658c1.642 4.953-2.578 22.297-2.578 22.297s-13.732-11.4-15.374-16.359c-1.64-4.957 1.05-10.305 6.007-11.945a9.454 9.454 0 0111.945 6.007zM180.529 293.201c-1.313 5.052-14.279 17.317-14.279 17.317s-5.331-17.032-4.012-22.084a9.454 9.454 0 0115.851-4.446 9.452 9.452 0 012.44 9.213zM195.411 340.33c-3.689 3.7-21.072 7.739-21.072 7.739s4.001-17.394 7.663-21.088a9.453 9.453 0 0113.387 13.349h.022zM198.634 378.548c-3.01 4.264-19.462 11.187-19.462 11.187s1.002-17.82 4.012-22.083a9.453 9.453 0 1115.45 10.896zM147.735 317.715c4.696 2.277 22.439.372 22.439.372s-9.501-15.105-14.23-17.388a9.452 9.452 0 00-12.323 4.543 9.45 9.45 0 004.114 12.473zM152.064 362.337c5.166.766 21.531-6.36 21.531-6.36s-13.59-11.575-18.745-12.341a9.453 9.453 0 00-10.741 7.96 9.453 9.453 0 007.96 10.741h-.005zM162.233 405.126c4.964 1.642 22.297-2.627 22.297-2.627s-11.439-13.683-16.419-15.325c-4.96-1.629-10.302 1.071-11.931 6.031-1.63 4.961 1.071 10.302 6.031 11.932l.022-.011z"
          fill="#35b775"
        />
        <path
          d="M156.612 270.658c1.642 4.953-2.578 22.297-2.578 22.297s-13.732-11.4-15.374-16.359c-1.64-4.957 1.05-10.305 6.007-11.945a9.454 9.454 0 0111.945 6.007zM180.529 293.201c-1.313 5.052-14.279 17.317-14.279 17.317s-5.331-17.032-4.012-22.084a9.454 9.454 0 0115.851-4.446 9.452 9.452 0 012.44 9.213zM195.411 340.33c-3.689 3.7-21.072 7.739-21.072 7.739s4.001-17.394 7.663-21.088a9.453 9.453 0 0113.387 13.349h.022zM198.634 378.548c-3.01 4.264-19.462 11.187-19.462 11.187s1.002-17.82 4.012-22.083a9.453 9.453 0 1115.45 10.896zM147.735 317.715c4.696 2.277 22.439.372 22.439.372s-9.501-15.105-14.23-17.388a9.452 9.452 0 00-12.323 4.543 9.45 9.45 0 004.114 12.473zM152.064 362.337c5.166.766 21.531-6.36 21.531-6.36s-13.59-11.575-18.745-12.341a9.453 9.453 0 00-10.741 7.96 9.453 9.453 0 007.96 10.741h-.005zM162.233 405.126c4.964 1.642 22.297-2.627 22.297-2.627s-11.439-13.683-16.419-15.325c-4.96-1.629-10.302 1.071-11.931 6.031-1.63 4.961 1.071 10.302 6.031 11.932l.022-.011z"
          opacity={0.25}
        />
        <path
          stroke="#535461"
          strokeWidth={2}
          d="M194.891 420.576s-37.918-23.118-47.955-56.372a67.32 67.32 0 00-24.334-34.913 113.038 113.038 0 00-17.738-10.656"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path
          d="M98.417 297.749c3.508 3.864 6.677 21.427 6.677 21.427s-17.17-4.86-20.677-8.724a9.451 9.451 0 01.648-13.351c3.866-3.508 9.844-3.218 13.352.648zM129.422 308.663c.843 5.155-6.021 21.624-6.021 21.624s-11.789-13.415-12.637-18.57c-.843-5.153 2.65-10.013 7.802-10.856 5.152-.844 10.012 2.65 10.856 7.802zM162.123 345.71c-1.877 4.876-16.129 15.615-16.129 15.615s-3.393-17.514-1.521-22.396a9.454 9.454 0 1117.65 6.781z"
          fill="#2196f3"
        />
        <path
          d="M180.573 379.331c-1.023 5.123-13.261 18.116-13.261 18.116s-6.305-16.698-5.282-21.816a9.455 9.455 0 0111.122-7.421c5.12 1.021 8.443 6.001 7.421 11.121z"
          fill="#35b775"
        />
        <path
          d="M131.408 383.403c5.036-1.39 17.109-14.536 17.109-14.536s-17.114-5.096-22.127-3.684a9.454 9.454 0 00-4.19 15.898 9.45 9.45 0 009.225 2.322h-.017z"
          fill="#2196f3"
        />
        <path
          d="M158.046 418.403c5.199-.547 19.32-11.438 19.32-11.438s-16.009-7.892-21.203-7.373c-5.194.52-8.984 5.153-8.464 10.347a9.452 9.452 0 0010.347 8.464z"
          fill="#35b775"
        />
        <path
          d="M251.564 521.111c0-3.21 43.862-5.812 97.969-5.812 54.106 0 97.968 2.602 97.968 5.812 0 3.21-43.862 5.813-97.968 5.813-54.107 0-97.969-2.603-97.969-5.813z"
          opacity={0.1}
          fill="#35b775"
        />
        <path
          d="M394.571 510.893v3.508H290.79v-2.857a34.115 34.115 0 002.348-63.006h100.748a34.135 34.135 0 00.685 62.355z"
          fill="#c8cad7"
        />
        <path
          d="M393.886 448.544a34.167 34.167 0 00-18.86 22.62h-63.028a34.167 34.167 0 00-18.86-22.62h100.748z"
          opacity={0.1}
        />
        <path
          d="M171.931 430.663v19.703c0 10.881 7.963 19.703 17.788 19.703h305.398c9.824 0 17.787-8.822 17.787-19.703v-19.703H171.931z"
          fill="#c8cad7"
        />
        <path
          d="M394.571 510.893v3.508H290.79v-2.857a34.032 34.032 0 008.002-4.258h89.43a34.134 34.134 0 006.349 3.607z"
          opacity={0.1}
        />
        <path
          d="M261.69 508.381h162.003a5.2 5.2 0 010 10.399H261.69a5.2 5.2 0 110-10.399z"
          fill="#c8cad7"
        />
        <path
          d="M512.904 214.75c0-9.824-7.964-17.787-17.787-17.787H189.719c-9.824 0-17.788 7.963-17.788 17.787v219.744h340.973V214.75z"
          fill="#474157"
        />
        <path
          d="M189.992 213.929h304.851a8.757 8.757 0 018.757 8.757v188.821a8.757 8.757 0 01-8.757 8.757H189.992a8.757 8.757 0 01-8.757-8.757V222.686a8.757 8.757 0 018.757-8.757z"
          fill="#ededf4"
        />
        <path
          d="M338.313 205.446a4.105 4.105 0 118.21 0 4.105 4.105 0 01-8.21 0zM332.292 451.187c0-5.592 4.534-10.125 10.126-10.125s10.125 4.533 10.125 10.125-4.533 10.125-10.125 10.125-10.126-4.533-10.126-10.125z"
          fill="#fff"
        />
        <path
          d="M503.6 222.686H181.235a8.757 8.757 0 018.757-8.757h304.851a8.757 8.757 0 018.757 8.757z"
          fill="#c8cad7"
        />
        <path
          d="M187.803 218.308a2.19 2.19 0 114.38 0 2.19 2.19 0 01-4.38 0zM194.371 218.308a2.19 2.19 0 114.38 0 2.19 2.19 0 01-4.38 0zM200.938 218.308a2.19 2.19 0 014.379 0 2.189 2.189 0 11-4.379 0z"
          fill="#ededf4"
        />
        <path
          d="M193.276 230.075h82.644v4.926h-82.644v-4.926zM193.276 374.017h82.644v4.926h-82.644v-4.926z"
          fill="#35b775"
        />
        <path
          d="M193.276 384.963h153.246v4.926H193.276v-4.926zM193.276 392.083h153.246v4.926H193.276v-4.926zM193.276 399.198h153.246v4.926H193.276v-4.926zM193.276 243.21h152.152v82.644H193.276V243.21zM194.918 333.516h35.575v32.838h-35.575v-32.838zM309.853 333.516h35.575v32.838h-35.575v-32.838zM251.564 333.516h35.575v32.838h-35.575v-32.838zM377.905 267.248h104.717v77.077H377.905v-77.077z"
          opacity={0.2}
          fill="#35b775"
        />
        <path
          d="M388.939 282.89h82.643v4.926h-82.643v-4.926zM388.939 293.108h82.643v4.926h-82.643v-4.926zM388.939 303.326h82.643v4.926h-82.643v-4.926zM388.939 313.539h82.643v4.926h-82.643v-4.926zM388.939 323.757h82.643v4.926h-82.643v-4.926z"
          fill="#ededf4"
        />
        <path
          d="M390.674 353.082h79.179v13.864h-79.179v-13.864zM386.443 399.275h53.094v9.299h-53.094v-9.299z"
          fill="#35b775"
        />
        <path
          d="M495.319 430.849c-1.483 1.002-4.094.963-4.959.827l-.312-4.275 5.282.673a16.174 16.174 0 00-.011 2.775zM434.371 480.178a20.237 20.237 0 013.749 0c2.085.17-3.749 0-3.749 0zM434.371 482.066a20.237 20.237 0 013.749 0c2.085.192-3.749 0-3.749 0zM462.858 477.962a20.237 20.237 0 013.749 0c2.086.197-3.749 0-3.749 0zM463.499 479.472a20.237 20.237 0 013.749 0c2.079.192-3.749 0-3.749 0z"
          opacity={0.1}
        />
        <path
          d="M89.924 317.165c5.159-.825 21.603 6.096 21.603 6.096s-13.455 11.742-18.614 12.572a9.452 9.452 0 01-10.828-7.839c-.826-5.156 2.684-10.004 7.84-10.829z"
          fill="#2196f3"
        />
      </g>
      <g>
        <motion.g
          animate={{ rotate: [0, 10, 0] }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <path
            d="M1662.46 540.353c-52.91-19.772-103.25-29.127-144.06 0v-94.004c0-39.78 32.25-72.029 72.03-72.029s72.03 32.249 72.03 72.029v94.004z"
            fill="#2f2e41"
          />
        </motion.g>
        <path
          d="M1564.79 489.078s25.64 63.483 18.31 75.691l64.71-34.183s-25.42-33.479-25.42-40.804l-57.6-.704z"
          fill="#fbc5c5"
        />
        <path
          shadowColor="#000000"
          shadowOpacity={0.127}
          shadowRadius={2.854}
          d="M1529.39 453.674c0-29.667 24.05-53.716 53.71-53.716 29.67 0 53.72 24.049 53.72 53.716s-24.05 53.716-53.72 53.716c-29.66 0-53.71-24.049-53.71-53.716z"
          shadowOffset={4.643}
          fill="#fbc5c5"
          shadowAngle={1.571}
        />
        <path
          d="M1635.6 392.702v-1.29h-1.53a72.085 72.085 0 00-61.95-12.379 71.736 71.736 0 0127.64 14.284l-52.06 2.893-21.97 48.918h14.87l11.98-21.975v21.975h50.3l3.42-17.091 9.5 17.091h9.88c.05 1.924 2.32.8-3.42 46.959-1.5 12.119-3.56 27.497-6.35 47.045l-9.77 94.004 46.4-19.533V448.791a71.923 71.923 0 00-16.94-56.089z"
          fill="#2f2e41"
        />
        <path
          d="M1621.43 448.791c0-6.068 2.74-10.988 6.11-10.988s6.1 4.92 6.1 10.988-2.73 10.987-6.1 10.987c-3.37 0-6.11-4.919-6.11-10.987z"
          fill="#fbc5c5"
        />
      </g>
    </svg>
  )
}

export default SvgSoftwareDevelopmentForElectronics
