import React from 'react'
import Img from 'gatsby-image'

const PictureColumn = props => (
  <div className="column is-3 is-relative" style={{ minHeight: 240 }}>
    <Img
      fluid={props.fluid}
      style={{
        position: 'absolute',
        overflow: 'hidden',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 1,
      }}
      alt={props.alt}
    />
  </div>
)

export default PictureColumn
