import React from 'react'

const TextColumn = props => (
  <div
    className={`column is-3 ${props.background}`}
    style={{ padding: '5rem' }}
  >
    <div className="content has-text-centered is-white">
      <h3 className="is-white">{props.title}</h3>
      <p>{props.text}</p>
    </div>
  </div>
)

export default TextColumn
