import React from 'react'
import { graphql } from 'gatsby'
import CallToAction from '../../components/common/callToAction'
import ProductHeader from '../../components/header/productHeader'
import CommonFooter from '../../components/footer/commonFooter'
import Section16 from '../../components/sections/section16'
import Grid from '../../components/common/grid'
import Seo from '../../components/seo/seo'
import SvgSoftwareDevelopmentForElectronics from '../../illustrations/SoftwareDevelopment/SoftwareDevelopmentForElectronics'
import { useTranslation } from 'react-i18next'

const SoftwareDevelopment = ({ data }) => {
  const { t } = useTranslation();

  return (
  <>
    <Seo
      title={t('softwareDev.seoTitle')}
      description={t('softwareDev.seoDescription')}
      seoImage={data.modernUI.childImageSharp.resize.src}
    />
    <ProductHeader
      isGradient=""
      headerTitle={t('softwareDev.headerTitle')}
      headerSubtitle={t('softwareDev.headerSubtitle')}
      animatedIllustration={<SvgSoftwareDevelopmentForElectronics />}
    />
    <Section16
      //   sectionTitle={<>{t('index.industriesTitle')}</>}
      sectionTitle={t('softwareDev.sectionTitle')}
      //   sectionDesc={<>{t('index.industriesDesc')}</>}
      sectionDesc={t('softwareDev.sectionDesc')}
      features={[1, 2, 3]}
      featureTitle={[
        <>{t('softwareDev.featureTitleOne')}</>,
        <>{t('softwareDev.featureTitleTwo')}</>,
        <>{t('softwareDev.featureTitleThree')}</>,
      ]}
      featureDesc={[
        <>{t('softwareDev.featureDescOne')}</>,
        <>{t('softwareDev.featureDescTwo')}</>,
        <>{t('softwareDev.featureDescThree')}</>,
      ]}
    />
    <CallToAction
      title={t('softwareDev.callToActionTitle')}
      link="/contatti"
      buttonText={t('softwareDev.callToActionButton')}
    />
    <Grid
      background="is-gradient-bottom-right"
      title1={t('softwareDev.title1')}
      fluid1={data.technologyStack.childImageSharp.fluid}
      alt1={t('softwareDev.altTechStackImage')}
      title2={t('softwareDev.title2')}
      fluid2={data.cloudInfrastructure.childImageSharp.fluid}
      alt2={t('softwareDev.altCloudImage')}
      fluid3={data.modernUI.childImageSharp.fluid}
      alt3={t('softwareDev.altModernUIImage')}
      title3={t('softwareDev.title3')}
      fluid4={data.cleanCode.childImageSharp.fluid}
      alt4={t('softwareDev.altCleanCodeImage')}
      title4={t('softwareDev.title4')}
    />
    <CommonFooter />
  </>
)
}

export default SoftwareDevelopment

export const softwareQuery = graphql`
  query {
    technologyStack: file(
      relativePath: { eq: "illustrations/technology_stack.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cleanCode: file(relativePath: { eq: "illustrations/clean_code.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cloudInfrastructure: file(
      relativePath: { eq: "illustrations/cloud_infrastructure.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    modernUI: file(relativePath: { eq: "illustrations/modern_UI.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
